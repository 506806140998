<template>
  <div
    v-if="show"
    class="linear-block">
    <v-progress-linear
      :height="$vuetify.breakpoint.mobile ? 14 : 20"
      color="green"
      bottom
      indeterminate>
      <template>
        <p class="linear-block__title white--text">
          {{ title }}
        </p>
      </template>
    </v-progress-linear>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'AppProgressLinear',
  computed: {
    ...mapState(['progressLinear']),
    show() {
      return this.progressLinear.show;
    },
    title() {
      return this.progressLinear.title;
    },
  },
};
</script>
<style scoped lang="scss">
  .linear-block {
    position: absolute;
    bottom: 0;
    width: 100%;
    flex-direction: column;
    z-index: 111111;
    background: #fff;

    &__title {
      text-align: center;
      font-size: 13px;
      margin-bottom: 0;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
</style>
